<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{$vuetify.lang.t('$vuetify.patient.label')}}
      </v-card-title>
      <v-card-text>
        <base-form
          v-if="editItem"
          :editStatus="true"
          :editItem="editItem"
          @update="handleSubmit"
          @cancel="handleCancel"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        editItem: null
      }
    },

    computed: {
      ...mapGetters({
        currentPatient: 'patient/currentPatient'
      })
    },

    created () {
      if (this.id) {
        this.patientShow( this.id ).then( () => {
          this.editItem = this.currentPatient
        } )
      }
    },

    methods: {
      ...mapActions({
        patientShow: 'patient/show',
        patientUpdate: 'patient/update'
      }),
      handleSubmit (data) {
        this.patientUpdate(data).then(() => {
          this.$router.push({name: 'patientList'})
        })
      },
      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
